/**
 * @generated SignedSource<<39e33a35b30dbdf3b2775d7e04bdfc72>>
 * @relayHash 036640a44dc4286acdab5b4b187736c7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID eb6f1d42fbe89f3bf1cda260bd96529f7209ffa993f1f2e6fb7fb4ae877d95bc

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActorNavigatorQuery$variables = {
  id: string;
};
export type ActorNavigatorQuery$data = {
  readonly nodeBySlugId: {
    readonly __typename: string;
  } | null | undefined;
};
export type ActorNavigatorQuery = {
  response: ActorNavigatorQuery$data;
  variables: ActorNavigatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActorNavigatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeBySlugId",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActorNavigatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeBySlugId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "eb6f1d42fbe89f3bf1cda260bd96529f7209ffa993f1f2e6fb7fb4ae877d95bc",
    "metadata": {},
    "name": "ActorNavigatorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "008bd271a74004710fb61563f2e3d1f4";

export default node;
