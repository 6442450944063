import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay'

import { useParams } from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'

import { wrapSuspenseScreen } from '@components'

import { ActorNavigatorQuery } from './__generated__/ActorNavigatorQuery.graphql'

const UserNavigator = wrapSuspenseScreen(
  loadable(() => import('./UserNavigator'))
)

const GuildNavigator = wrapSuspenseScreen(
  loadable(() => import('./GuildNavigator/GuildNavigator'))
)

export const ActorNavigator = () => {
  const params = useParams()

  const data = useLazyLoadQuery<ActorNavigatorQuery>(
    graphql`
      query ActorNavigatorQuery($id: String!) {
        nodeBySlugId(id: $id) {
          __typename
        }
      }
    `,
    { id: params.actorId || '' }
  )

  if (!data.nodeBySlugId) {
    console.warn('No Actor Found', params.id)
    return null
  }

  switch (data.nodeBySlugId.__typename) {
    case 'User': {
      return <UserNavigator />
    }
    case 'Guild': {
      return <GuildNavigator />
    }
    default: {
      console.error('No Actor Found', params.id)
      return null
    }
  }
}

export default ActorNavigator
